/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"

import Header from "./header"
import Footer from "./footer"
import Nav from "./nav"
import "../styles/global.css"
import "../styles/normalize.css"
import "../styles/layout.css"

import { useStaticQuery, graphql } from "gatsby"

const Layout = ({ children }) => {
  const { directusSiteDetail } = useStaticQuery(
    graphql`
      {
        directusSiteDetail {
          banner {
            localFile {
              publicURL
            }
          }
        }
      }
    `
  )

  return (
    <>
      <div className="pageContainer">
        <Header />
        <Nav />
        <div className="container">{children}</div>
        <Footer />
      </div>{" "}
      <div
        className="pageBackground"
        style={{
          background: `url(${directusSiteDetail.banner.localFile.publicURL})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "top center",
        }}
      />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
