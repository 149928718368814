import React from "react"
import { useState } from "react"
import Modal from "react-modal"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons"
import NewQuoteForm from "./newQuoteForm"

import "../../styles/quoteModal.css"

Modal.setAppElement("#___gatsby")

const QUOTE_MODAL = {
  buttonText: "Get A Quote",
  contentLabel: "Get a Quote",
  modalContent: <NewQuoteForm />,
}

const QuoteModal = ({ className, buttonStyle }) => {
  const [isOpen, setIsOpen] = useState(false)

  const toggleModal = e => {
    setIsOpen(!isOpen)
  }

  return (
    <>
      <button
        className={className}
        onClick={toggleModal}
        style={buttonStyle || {}}
      >
        {QUOTE_MODAL.buttonText}
      </button>

      {isOpen ? (
        <Modal
          id="quoteModal"
          isOpen={isOpen}
          contentLabel={QUOTE_MODAL.contentLabel}
          shouldCloseOnOverlayClick={true}
          onRequestClose={toggleModal}
          className="quoteModal"
          overlayClassName="overlay"
          closeTimeoutMS={250}
        >
          <h4 className="center">{QUOTE_MODAL.contentLabel}</h4>
          <FontAwesomeIcon
            onClick={toggleModal}
            icon={faTimesCircle}
            alt="Close"
            className="modalCloseButton"
          />
          <br />
          {QUOTE_MODAL.modalContent}
        </Modal>
      ) : null}
    </>
  )
}

export default QuoteModal
