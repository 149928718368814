import React from "react";
import { useStaticQuery, graphql } from "gatsby"


import "../styles/footer.css";

const Footer = props => {
    const { directusSiteDetail } = useStaticQuery(
        graphql`
          {
            directusSiteDetail {
              footer
            }
          }
        `
      )
    if (!directusSiteDetail || !directusSiteDetail.footer) return (<></>)
    
    return (<footer dangerouslySetInnerHTML={{ __html: directusSiteDetail.footer }}/>)
};

export default Footer;
