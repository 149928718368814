import { Link, useStaticQuery, graphql } from "gatsby"
import React from "react"

import QuoteModal from "../components/quoteForm/quoteModal"
import SocialLinks from "../components/socialLinks"

import "../styles/header.css"

const generatePhoneSlug = (phone)  => {
  return phone.replace(/(\D|x[.:-]?|ex[.:-]?)/gi, '');
}

const Header = (props) => {
  const { directusSiteDetail } = useStaticQuery(
    graphql`
      {
        directusSiteDetail {
          title
          phone
          description
          logo {
            title
            localFile {
              publicURL
            }
          }
        }
      }
      `
  )

  const { title, logo, description, phone } = directusSiteDetail

  return (
    <header className="bg-white">
      <Link to="/">
          <div className="logo">
            <img
              src={logo.localFile.publicURL}
              alt={(logo.title) ?  logo.title : title}
            />
            <p className="description">{description}</p>
          </div>
      </Link>
      <div className="contact">
        <span className="phone">
          <a href={`tel:${generatePhoneSlug(phone)}`}>{phone}</a>
        </span>
        <QuoteModal
          buttonStyle={{
            borderRadius: "0.33em",
            border: "1px solid black",
            background: "black",
            color: "white",
            fontWeight: "bold",
            fontSize: "0.8rem",
            letterSpacing: ".05rem",
            padding: "3px 6px",
          }}
        />

        <SocialLinks />
      </div>
    </header>
  )
}

export default Header
