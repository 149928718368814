import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"

import "../styles/nav.css"

const buildLinks = array => {
  let links = []

  array.forEach(node => {
    if (!node || !node.label || !node.url) return

    links.push({
      id: node.id,
      label: node.label,
      to: node.url,
    })
  })

  return links
}

const Nav = (props) => {
  const { allDirectusMenu } = useStaticQuery(
    graphql`
      {
        allDirectusMenu(sort: {fields: sort, order: ASC}) {
          nodes {
            id
            sort
            url
            label
          }
        }
      }
      `
  )

  const navLinks = buildLinks(allDirectusMenu.nodes);

  return (
    <nav className="bg-white">
      <input type="checkbox" />
      <div className="icon">
        <span />
        <span />
        <span />
      </div>
      <ul>
        {navLinks.map(({ to, label, submenu }, index) => (
          <React.Fragment key={index}>
            {submenu ? (
              <>
                <input type="checkbox" />
                <div className="subIcon">
                  <span />
                  <span />
                </div>
              </>
            ) : (
              ""
            )}

            <li>
              <Link key={index} to={to} activeClassName="current-page">
                {label}
              </Link>
            </li>

            {submenu ? (
              <>
                <ul>
                  {submenu.map((menuItem, index) => (
                    <li>
                      <Link
                        key={index}
                        to={menuItem.to}
                        activeClassName="current-page"
                      >
                        {menuItem.label}
                      </Link>
                    </li>
                  ))}
                </ul>
              </>
            ) : (
              ""
            )}
          </React.Fragment>
        ))}
      </ul>
    </nav>
  )
}

export default Nav
