import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import { library } from "@fortawesome/fontawesome-svg-core"
import { fab } from "@fortawesome/free-brands-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

//add all fontawesome brand fonts to the library.
library.add(fab)

const SocialLinks = (props) => {
  const { allDirectusSocialmedia } = useStaticQuery(
    graphql`
      {
        allDirectusSocialmedia {
          nodes {
            site
            link
            icon
            id
          }
        }
      }
    `
  )

  const social = allDirectusSocialmedia.nodes

  return (
    <div className="socialLinks">
      {social.map(({ site, link, icon, id }) => (
        <a name={site} key={id} href={link} target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={["fab", icon]} alt={site} />
        </a>
      ))}
    </div>
  )
}

export default SocialLinks
