import React from "react";
import Form from 'react-jsonschema-form';
import FileWidget from "./FileWidget"

import "../../styles/newQuoteForm.css"

// function LocationFileField({ TitleField, properties, title, description}) {
//     return (
//         <div>
//             <TitleField title={title} />
            
//         </div>
//     )
// }

const schema = {
    definitions: {
        shirtLocations: {
            type: "array",
            title: "Decoration Locations",
            items: {
                type: "string",
                enum: [
                    "Left Chest",
                    "Right Chest",
                    "Front",
                    "Back",
                    "Yoke",
                    "Sleeve",
                    "Other",
                ]
            },
            uniqueItems: true
        },
        shirtLocationsDropdown: {
            type: "string",
            title: "Imprint Location",
            anyOf: [
                {
                    type: "string",
                    enum: ["LC"],
                    title: "Left Chest"
                },{
                    type: "string",
                    enum: ["RC"],
                    title: "Right Chest"
                },{
                    type: "string",
                    enum: ["FRONT"],
                    title: "Front"
                },{
                    type: "string",
                    enum: ["BACK"],
                    title: "Back"
                },{
                    type: "string",
                    enum: ["SLEEVE"],
                    title: "Sleeve"
                },{
                    type: "string",
                    enum: ["OTHER"],
                    title: "Other"
                },
                
            ]
        },
        files: {
            type: "array",
            title: " ",
            items: {
                type: "string",
                format: "data-url"
            }
        }
    },
    title: "Request a Quote",
    type: "object",
    required: ["firstName", "lastName", "email"],
    properties: {
        customer: {
            type: "object",
            title: "First, tell us who you are.",
            properties: {
                "firstName": {
                    "type": "string",
                    "title": "First"
                },
                "lastName": {
                    "type": "string",
                    "title": "Last"
                },
                company: {
                    type: "string",
                    title: "Company Name"
                },
                email: {
                    type: "string",
                    title: "Email",
                    format: "email"
                },
                phone: {
                    type: "string",
                    title: "Phone",
                    minLength: 10
                },
            }
        },
        quoteItems: {
            type: "array",
            title: "Next, what can we create for you?",
            items: {
                type: "object",
                properties: {
                    itemType: {
                        title: "Item Type",
                        type: "integer",
                        default: 0,
                        anyOf: [
                            {
                                title: "Short Sleeve T-Shirt",
                                enum: [1]
                            },
                            {
                                title: "Long Sleeve T-Shirt",
                                enum: [2]
                            },
                            {
                                title: "Tote Bag",
                                enum: [3]
                            },
                        ]
                    }
                },
                dependencies: {
                    itemType: {
                        oneOf: [
                            {
                                properties: {
                                    itemType: {
                                        enum: [0]
                                    }
                                }
                            },
                            {
                                properties: {
                                    itemType: {
                                        enum: [1, 2]
                                    },
                                    imprints: {
                                        type: "array",
                                        title: "Imprints",
                                        items: {
                                            type: "object",
                                            properties: {
                                                locations: {
                                                    "$ref": "#/definitions/shirtLocationsDropdown"
                                                },
                                                files: {
                                                    type: "array",
                                                    title: " ",
                                                    items: {
                                                        type: "string",
                                                        format: "data-url"
                                                    }
                                                },
                                                comment: {
                                                    type: "string",
                                                    title: "Note"
                                                }
                                            },
                                            // dependencies: { // ADD A COMMENTS BOX FOR FILE SELECTION
                                            //     locations: {
                                            //         oneOf: [
                                            //             {
                                            //                 properties: {
                                            //                     locations: {
                                            //                         enum: ["OTHER"],
                                            //                     },
                                            //                     comment: {
                                            //                         type: "string",
                                            //                         title: "Comment"
                                            //                     }
                                            //                 }
                                            //             }
                                            //         ]
                                            //     }
                                            // }
                                        }
                                    },
                                }
                            },
                            { ///// EXAMPLE NESTED DEPENDENCY
                                properties: {
                                    itemType: {
                                        enum: [3]
                                    },
                                    test: {
                                        type: "string",
                                        title: "Test3"
                                    }
                                },
                                dependencies: {
                                    test: {
                                        type: "object",
                                        properties: {
                                            files: {
                                                "$ref": "#/definitions/files"
                                            },
                                        }
                                    }
                                }
                            },
                        ]
                    }
                }

            }
        },
        comments: {
            type: "object",
            title: "Finally, any other comments?",
            properties: {
                comment: {
                    "type": "string",
                    "title": " "
                }
            }
        },
    }
};

const uiSchema = {
    customer: {
        email: {
            "ui:options": {
                inputType: "email",
            }
        },
        phone: {
            "ui:options": {
                inputType: "tel",
            }
        }
    },
    quoteItems: {
        items: {
            itemType: {
                "ui:widget": "radio",
            },
            files: {
                "ui:widget": (props) => {               
                    return (<FileWidget {...props} accept="image/*,font/*,.pdf,.psd,.psb,.pcx,.raw,.eps,.ai" />)},
            },
            locations: {
                "ui:widget": "checkboxes"
            },
            imprints: {
                items: {
                    locations: {
                        "ui:widget": "select"
                    },
                    files: {
                        "ui:widget": (props) => {                          
                            return (<FileWidget {...props} accept="image/*,font/*,.pdf,.psd,.psb,.pcx,.raw,.eps,.ai" />)},
                    },
                }
            }
        }
    },
    comments: {
        comment: {
            "ui:widget": "textarea"
        }
    }

}

const log = (type) => {}//console.log.bind(console, type);

const NewQuoteForm = props => (
    <div>
        <Form
            schema={schema}
            uiSchema={uiSchema}
            onChange={log("changed")}
            onSubmit={log("submitted")}
            onError={log("errors")}
        />
    </div>
)

export default NewQuoteForm;